/* Add this to your CSS file */
.modalrender {
    /* display: none; */
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);

  }
  
  .modal-content {
    width: 95% !important;
    background-color: #971414;
    /* margin: 15% auto; */
    padding: 20px;
    margin-top: 50px;
    margin-left: 34px;
    border: 1px solid #9e2e2e;
    width: 80%;
  }
  
  .close {
    color: #661c1c;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover {
    color: #000;
  }
  