.form-container{
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    grid-gap: 10px;
    /* grid-template-columns: 1fr 1fr;
    width: 150%;
    margin-left: -25%; */
}

h1{
  align-self: center;
  font-size: 1.5rem !important;
}

input{
  display: inline-block;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.phoneView{
  display: none;
}

button.mb-4.w-100{
  margin-top: 15px;
}

button:hover{
  background: #000 !important;
}

.form-outline{
  margin-top: 25px !important;
}

tr .inp-td .form-outline{
  margin-top: 1px !important;
}
/* .tableMargin{
  margin-top: 8rem !important;
} */