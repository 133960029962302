#imgVecal img{
    width: 200px;
    height: 200px;
    margin: 10px;
}


span img{
    width: 100px;
    height: 100px;
    margin: 10px;
}

.filterbtn{
    border-color: #5a8dee !important;
    background-color: #1976d2 !important;
    color: #fff;
    margin-right: 170px;
}