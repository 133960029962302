.toolbar{
    display: inline-block;
}

h5{
    display: inline-block;
    margin-top: 150px;
}

@media screen and (max-width: 580px) {
    .toolbar, h5 {
        display: block;
    }

    .inp{
        height: 50px;
    }

    .form-outline{
        /* width: 438px;
        margin-left : -100px; */
    }
    /* #misDiv{
        display: none;
    }
    .toolbar{
        display: none;
    } */
    /* #phoneView{
        display: block !important;
    }
    #bankTable{
        display: none;
    } */
}



.subMenu{
    display: flex;
    list-style: none;
}
li{
    padding: 5px;
    margin: 5px;
    color: black
}
li a{
    font-size: 22px;
    font-weight: 500;
}
li a:hover{
    color: black;
}


.subMenu li a {
    color: #35424e;
}